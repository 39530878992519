.darkForm{
    background-color: #1c1c1c;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #2c2c2c;
}
.darkForm:focus{
    background-color: #2c2c2c;
    color: #fff;
    border: 1px solid #2c2c2c;
}
.darkForm:disabled{
    background-color: #2c2c2c;
    color: grey;
    border: 1px solid #2c2c2c;
}
.darkCheckbox{
    background-color: #171717;
}
.tags {
    text-transform: capitalize;
    margin-top: 10px;
}

.alert-dark{
    color: white;
    background-color: #464646;
    border-color: #464646;
    
}