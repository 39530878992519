.serverDetailLogo {
    border-radius: 10px;
}

.detail-info{
    font-size: 1.8em;
    display: flex;
    justify-content: space-between;
}


.detailsMotd{
    margin-top: 5vh;
    font-size: 3em;
}

.url-container{
    font-size: 3em;
    margin-bottom: 10px;
    text-align: center;
    transition: 0.5s;
}
.url-container:active{
    transform: scale(0.75);
}

@media screen and (max-width: 576px) {
    .detailsMotd{
        font-size: 1em;
    }
}

@media screen and (max-width: 768px) and (min-width: 576px) {
    .detailsMotd{
        font-size: 1.5em;
    }
    .url-container{
        font-size: 2em;
    }
}

@media screen and (max-width: 1200px) and (min-width: 769px) {
    .detailsMotd{
        font-size: 2em;
    }
}


.players{
    color: red;
}

.detail-tags{
    margin-bottom: 10px;
}

.version {
    color: green;
}

.detailsAbout{
    font-size: 1.8em;
    margin-bottom: 10px;
    max-height: 5vh;
}

