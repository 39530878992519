.tag {
    margin-right: 5px;
    font-size: 1em;
    padding: 5px 7px;
    text-transform: capitalize;
}

@media screen and (max-width: 768px) {
    .tag {
        font-size: 0.85em;
        padding: 3px;
    }
}
