.serverCard {
    font-size: inherit;
    color: #e6e6e6;
    margin: auto;
    min-height: 150px;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #333333;
    transition-duration: 0.2s;
    max-width: 95vw;
    position: relative;
}

.serverCard:hover {
    background-color: #444444;
    transform: scale(1.015);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.serverCard-light {
    color: #555;
    background-color: #f2f2f2;
}

.serverCard-light:hover {
    background-color: #e8e8e8;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.serverCard-image-container{
    padding-left: 0px;
    border-radius: 10px 0px 0px 10px;
    background: #404040;
    max-width: 150px;
    position: absolute;
    height: 100%;
}

.serverCard-light .serverCard-image-container{
    background-color: #e0e0e0 !important;
}

.serverCard-image{
    border-radius: 10px 0px 0px 10px;
    width: 150px;
    position: relative;
    top: calc(50% - 75px);
}

.serverCard-name{
    font-size: 1.5em;
    font-weight: bold;
}

.serverCard-motd{
    color: #999999;
    height: 100%; overflow: auto;
    padding-bottom: 1em;
    font-size: 1.6em;
}

.serverCard-players{
    font-size: 0.8em;
    position: absolute;
    top: 7px;
    right: 10px;

}
.serverCard-place{
    font-size: 0.8em;
}


.serverCard-tags{
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 0.7em;
}

@media screen and (max-width: 518px) {
    .serverCard-players{
        top: 7px;
        left: -143px;
    }
}

.serverCard-content{
    position: relative;
    left: 155px;
    max-width: calc(100% - 160px);
}

    
